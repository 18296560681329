import {usePage} from '@inertiajs/vue3'
import {computed} from "vue";
import {isFuture, isPast} from "date-fns";
import {formatInTimeZone} from "date-fns-tz";

const page = usePage()

const user = computed(() => page.props.auth.user)

const formats = {
    // h:mma dddd Do MMMM YYYY
    // 1:30pm AEDT Tuesday 19th December 2023
    DATETIME_FULL: {
        pattern: 'h:mmaaa \'__TZ__\', cccc do LLLL yyyy',
        hasTimezone: true
    },

    // h:mma D-MMM-YYYY
    // 12:00pm AEST 7-Jul-2024
    DATETIME_SHORT: {
        pattern: 'h:mmaaa \'__TZ__\', d-LLL-yyyy',
        hasTimezone: true
    },

    // Do MMMM YYYY
    // 19th December 2023
    DATE_FULL: {
        pattern: 'do LLLL yyyy',
        hasTimezone: false
    },

    // DD MMM YYYY
    // 09 Jul 2024
    DATE_SHORT: {
        pattern: 'dd LLL yyyy',
        hasTimezone: false
    },

    // h:mma, DD MMM YYYY
    // 1:30pm AEDT, 09 Jul 2024

    // MMM YYYY
    // Jul 2024
    MONTH: {
        pattern: 'LLL yyyy',
        hasTimezone: false
    },

};

const _format = (timestamp, format, useSystemTimezone = false) => {

    const date = new Date(Date.parse(timestamp));

    if (isNaN(date.valueOf())) {
        return 'Invalid Date';
    }

    if (!format) {
        return date;
    }

    let hasTimezone = false;
    let _format = format;
    if (formats?.[format]) {
        _format = formats[format].pattern;
        hasTimezone = formats[format].hasTimezone;
    }

    // what timezone
    let _targetTimezone = _timezone.value;
    if (useSystemTimezone) {
        _targetTimezone = _systemTimezone.value;
    }

    // format the timezone
    let formattedTimezone = null;
    if (hasTimezone) {
        formattedTimezone = formatInTimeZone(date, _targetTimezone, 'zzz');
        formattedTimezone = '<span class="opacity-70 text-[80%]">' + formattedTimezone + '</span>';
    }

    // format the plain date
    let formattedTimestamp = formatInTimeZone(date, _targetTimezone, _format)

    return formattedTimestamp.replace('__TZ__', formattedTimezone);
};

const _timezone = computed(() => {
    if (usePage()?.props?.auth?.user?.timezone) {
        return usePage().props.auth.user.timezone;
    }
    return _deviceTimezone.value;
});

const _systemTimezone = computed(() => {
    return usePage().props.system.timezone;
});

const _deviceTimezone = computed(() => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
});

const datetime = {
    install(app, options) {
        app.config.globalProperties.$datetime = {
            format: _format,
            timezone: _timezone,
            systemTimezone: _systemTimezone,
            deviceTimezone: _deviceTimezone,

            isFuture: (timestamp) => {
                return isFuture(new Date(Date.parse(timestamp)));
            },
            isPast: (timestamp) => {
                return isPast(new Date(Date.parse(timestamp)));
            }
        }
    }
}

export {datetime};