import './bootstrap';
import '../css/app.css';

import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import {i18nVue} from 'laravel-vue-i18n'
import {i18n} from './Plugins/i18n.js';
import {datetime} from './Plugins/datetime.js';

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const appName = window.document.getElementsByTagName('title')[0]?.innerText;

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    progress: {
        color: '#4B5563',
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        return createApp({
            render: () => h(App, props),
            mounted() {
                // preserving state when going back (for Toasts)
                window.addEventListener('popstate', () => {
                    this.$inertia.reload({preserveScroll: true, preserveState: true})
                });

            }
        })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(PerfectScrollbar)
            .use(i18nVue, {
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    if (lang.includes("php_")) {
                        return await langs[`../../lang/${lang}.json`]();
                    }
                }
            })
            .use(i18n)
            .use(datetime)
            .mount(el);
    }
});