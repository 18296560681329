import {trans} from "laravel-vue-i18n";

const i18n = {
    install(app, options) {
        app.config.globalProperties.__ = (key, replacements = {}) => {
            // convert null to empty string
            Object.keys(replacements).forEach(function (key) {
                if (replacements[key] === null || typeof replacements[key] == "undefined") {
                    replacements[key] = '';
                }
            })
            
            // translate
            return trans(key, replacements);
        }
    }
}

export {i18n};